import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["visibility"]

  connect() {
    // Inicializamos la visibilidad del campo al cargar el controlador
    this.toggleVisibility();
  }

  toggleVisibility() {
    const isPublicChecked = this.element.querySelector("#drill_public").checked;

    if (isPublicChecked) {
      this.visibilityTarget.classList.add("d-none");
      this.visibilityTarget.querySelector("select").value = ""; // Resetea el valor de visibilidad
    } else {
      this.visibilityTarget.classList.remove("d-none");
    }
  }
}
