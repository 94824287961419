import { Controller } from "@hotwired/stimulus"
import html2canvas from "html2canvas"

export default class extends Controller {
  download(event) {
    event.preventDefault()
    const elementId = event.currentTarget.dataset.elementId
    const width = event.currentTarget.dataset.width
    const height = event.currentTarget.dataset.height
    const filename = event.currentTarget.dataset.filename || 'download'
    const element = document.getElementById(elementId)
    const awardId = event.currentTarget.dataset.awardId // Assuming you have award ID

    if (!element) {
      console.error('Element not found:', elementId)
      return
    }

    console.log(element.outerHTML)

    element.classList.remove("d-none")
    element.classList.add("d-flex")

    html2canvas(element, {
      useCORS: true,
      allowTaint: false,
      logging: true,
      scale: 2, // Aumenta la escala para mayor resolución
      width: width,
      height: height
    }).then(canvas => {
      canvas.toBlob(blob => {
        const formData = new FormData()
        formData.append('image', blob, `${filename}.png`)

        // Send the image to the server
        fetch(`/awards/${awardId}/upload_image`, {
          method: 'POST',
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          },
          body: formData
        }).then(response => {
          if (response.ok) {
            console.log('Image successfully uploaded')
            // Reload the page after successful upload
            window.location.reload()
          } else {
            console.error('Error uploading image:', response.statusText)
            element.classList.remove("d-flex")
            element.classList.add("d-none")
          }
        }).catch(error => {
          console.error('Error uploading image:', error)
          element.classList.remove("d-flex")
          element.classList.add("d-none")
        })
      }, 'image/png')
    }).catch(error => {
      console.error('Error converting HTML to canvas:', error)
      element.classList.remove("d-flex")
      element.classList.add("d-none")
    })
  }
}
