import { Controller } from "stimulus";
import Timer from 'easytimer.js';

export default class extends Controller {
  static targets = ["display"];

  connect() {
    this.timer = new Timer();

    // Obtener el tiempo de inicio del servidor 
    let startTime = this.data.get("start-time");
    console.log("Start Time:", startTime);  // Añade esta línea

    if (startTime) {
      let now = new Date();
      let startTimeDate = new Date(startTime);
      console.log("Parsed Start Time:", startTimeDate);  // Añade esta línea

      let timeDifference = now.getTime() - startTimeDate.getTime();
      let secondsPassed = Math.floor(timeDifference / 1000);
      
      this.startTimer(secondsPassed);
    }

    if (this.hasStartTimeValue) {
      this.startTimer();
    } else {
        this.displayTarget.textContent = '0:00:00';
    }
  }


  startTimer(secondsPassed) {
    console.log("Starting timer with seconds passed:", secondsPassed);

    this.timer.start({ countdown: false, startValues: { seconds: secondsPassed } });

    this.timer.addEventListener('secondsUpdated', () => {
      this.displayTarget.textContent = this.timer.getTimeValues().toString();
    });
  }

  disconnect() {
    this.timer.stop();
  }
}
