import { Controller } from "stimulus";
import { Sortable, MultiDrag } from 'sortablejs';

export default class extends Controller {
  static targets = ["players", "formation", "listView", "attendanceView", "positionsView"];

  connect() {
    this.initializeSortable();
    this.initializeListView();
    this.initializeAttendanceView();
    this.initializePositionsView();
  }

  initializeSortable() {
    const handleSelector = this.data.get("handle")
    const isMultiDrag = this.formationTarget.dataset.formationMultiple === "true";

    this.sortablePlayers = Sortable.create(this.playersTarget, {
      group: 'shared',
      multiDrag: true,
      selectedClass: 'selected',
      fallbackTolerance: 3,
      animation: 150,
      ghostClass: 'sortable-ghost',
      handle: handleSelector || undefined,
      multiDrag: isMultiDrag,
      selectedClass: isMultiDrag ? 'selected' : undefined
    });

    this.formationTargets.forEach(formationTarget => {
      Sortable.create(formationTarget, {
        group: 'shared',
        multiDrag: true,
        selectedClass: 'selected',
        fallbackTolerance: 3,
        animation: 150,
        ghostClass: 'sortable-ghost',
        onAdd: event => this.handleAdd(event, formationTarget),
        onRemove: this.handleRemove.bind(this),
        onEnd: this.handleEnd.bind(this)
      });
    });
  }

  initializeAttendanceView() {
    const event = { target: this.attendanceViewTarget };
    event.target.checked = this.attendanceViewTarget.checked;
    this.attendanceView(event);
  }

  initializePositionsView() {
    const event = { target: this.positionsViewTarget };
    event.target.checked = this.positionsViewTarget.checked;
    this.positionsView(event);
  }

  initializeListView() {
    const event = { target: this.listViewTarget };
    event.target.checked = this.listViewTarget.checked
    this.listView(event);
  }

  listView(event) {
    const listView = event.target.checked;
    const avatars = document.querySelectorAll('.player-avatar');
    const playerCards = document.querySelectorAll('.player-card');
    const formationGrids = document.querySelectorAll('.formation-grid');
  
    avatars.forEach(avatar => {
      if (listView) {
        avatar.classList.remove('d-none');
        avatar.classList.add('d-inline');
      } else {
        avatar.classList.add('d-none');
        avatar.classList.remove('d-inline');
      }
    });

    playerCards.forEach(card => {
      if (listView) {
        card.classList.remove('player-card-small');
      } else {
        card.classList.add('player-card-small');
      }
    });

    formationGrids.forEach(grid => {
      if (listView) {
        grid.classList.remove('formation-grid--single-column');
      } else {
        grid.classList.add('formation-grid--single-column');
      }
    });
  }
  
  attendanceView(event) {
    const showAttendance = event.target.checked;
    const attendanceBadges = document.querySelectorAll('.attendance-percentage');
    
    attendanceBadges.forEach(badge => {
      if (showAttendance) {
        badge.classList.remove('d-none');
      } else {
        badge.classList.add('d-none');
      }
    });
  }

  positionsView(event) {
    const showPositions = event.target.checked;
    const positionBadges = document.querySelectorAll('.player-position');
    
    positionBadges.forEach(badge => {
      if (showPositions) {
        badge.classList.remove('d-none');
      } else {
        badge.classList.add('d-none');
      }
    });
  }

  handleAdd(event, formationTarget) {
    const playerId = event.item.dataset.playerId;
    const formationId = formationTarget.dataset.formationId;
    const position = Array.from(formationTarget.children).indexOf(event.item) + 1;

    fetch("/player_formations", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-CSRF-Token": getMetaValue("csrf-token"),
      },
      body: JSON.stringify({ player_id: playerId, formation_id: formationId, position: position}),
    })
    .then(response => response.json())
    .then(data => {
      if (data.success && data.player_formation_id) {
        event.item.dataset.playerFormationId = data.player_formation_id;
        
        // Llamar a handleEnd para actualizar las posiciones de los demás jugadores
        this.handleEnd({from: formationTarget});
      } else {
        // Manejo de errores
        console.error("Error al agregar jugador:", data.error);
      }
    });
  }

  handleRemove(event) {
    const playerFormationId = event.item.dataset.playerFormationId;

    // If playerFormationId doesn't exist (maybe the player was just added and not saved), we don't need to do anything
    if (!playerFormationId) return;

    fetch(`/player_formations/${playerFormationId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-CSRF-Token": getMetaValue("csrf-token"),
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then(data => {
      if (data.success) {
        // You might want to show a success message or do some cleanup here
      } else {
        // Handle errors
        console.error(data.errors);
      }
    })
    .catch(error => {
      console.error("There was a problem with the fetch operation:", error.message);
    });
  }

  handleEnd(event) {
    const items = event.from.children;
    const positions = Array.from(items).map((item, index) => {
      return {
        player_formation_id: item.dataset.playerFormationId,
        position: index + 1, // Los índices comienzan desde 0
        formation_id: event.from.dataset.formationId,
        player_id: item.dataset.playerId  // Añadir esta línea
      };
    });
  
    fetch("/player_formations/update_formation_and_positions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-CSRF-Token": getMetaValue("csrf-token"),
      },
      body: JSON.stringify({ positions }),
    })
    .then(response => response.json())
    .then(data => {
      // Manéjalo según tus necesidades
    });

    this.updatePositionNumbers(event.from);
  }

  movePlayer(event) {
    event.preventDefault();
    
    const playerId = event.currentTarget.dataset.playerId;
    const formationId = event.currentTarget.dataset.formationId;
  
    // Encuentra los elementos correspondientes
    const playerElement = document.querySelector(`[data-player-id="${playerId}"]`);
    const formationTarget = this.formationTargets.find(el => el.dataset.formationId === formationId);
  
    // Obtén la formación actual del jugador
    const currentFormation = playerElement.closest('[data-formation-target]');
    
    if (playerElement && formationTarget) {
      // Si el jugador ya está en la formación a la que se va a mover, no hacer nada.
      if (currentFormation === formationTarget) return;
      
      // Si está en alguna formación, removemos primero.
      if (currentFormation) {
        this.handleRemove({ item: playerElement });
        currentFormation.removeChild(playerElement);
      }
  
      // Mueve el jugador a la formación especificada
      formationTarget.appendChild(playerElement);
      
      // Llama a handleAdd para manejar cualquier lógica adicional
      this.handleAdd({ item: playerElement }, formationTarget);
    }
  }
  

  moveToAvailable(event) {
    event.preventDefault();
  
    // Obtiene el playerID del evento actual
    const playerId = event.currentTarget.dataset.playerId;
  
    // Encuentra los elementos correspondientes
    const playerElement = document.querySelector(`[data-player-id="${playerId}"]`);
    const availableList = this.playersTarget;  // Usar el target de Stimulus
  
    // Si el jugador y la lista están presentes
    if (playerElement && availableList) {
      // Mueve el jugador a la lista de disponibles
      availableList.appendChild(playerElement);
  
      // Llama a handleRemove para eliminar cualquier referencia en la base de datos si es necesario
      this.handleRemove({ item: playerElement });
    }
  }
  
  updatePositionNumbers(formationElement) {
    const playerCards = formationElement.querySelectorAll(".player-card");
    playerCards.forEach((card, index) => {
      const positionNumberElement = card.querySelector(".position-number");
      positionNumberElement.textContent = index + 1; // Los índices comienzan desde 0, por lo que añadimos 1
    });
  }

}

function getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`);
  return element.getAttribute("content");
}
