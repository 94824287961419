import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "url", "title", "text" ]

  share(event) {
    event.preventDefault()
    
    const title = this.titleTarget.value || '¡Mira este premio!';
    const text = this.textTarget.value || 'Este es el premio que he ganado:';
    const url = this.urlTarget.value || window.location.href;

    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const fileName = this.getFileNameFromUrl(url) || 'award.jpg';
        const file = new File([blob], fileName, { type: blob.type });
        
        if (navigator.canShare && navigator.canShare({ files: [file] })) {
          navigator.share({
            title: title,
            text: text,
            files: [file]
          }).then(() => {
            console.log('Contenido compartido con éxito');
          }).catch((error) => {
            console.error('Error al compartir', error);
          });
        } else if (navigator.share) {
          navigator.share({
            title: title,
            text: text,
            url: url
          }).then(() => {
            console.log('Contenido compartido con éxito');
          }).catch((error) => {
            console.error('Error al compartir', error);
          });
        } else {
          alert('La funcionalidad de compartir no está disponible en tu navegador.');
        }
      });
  }

  getFileNameFromUrl(url) {
    return url.split('/').pop().split('?')[0];
  }
}
