import { Controller } from "stimulus";
import debounce from "lodash/debounce";

export default class extends Controller {
  static targets = ["form", "input", "select", "checkbox"];

  connect() {
    // Para todos los inputs
    this.inputTargets.forEach(input => {
      input.addEventListener('input', this.handleInput);
    });

    // Para todos los selects
    this.selectTargets.forEach(select => {
        select.addEventListener('change', this.handleInput);
    });

    this.checkboxTargets.forEach(checkbox => {
      checkbox.addEventListener('change', this.handleCheckboxChange);
    });
  }

  handleInput = debounce(() => {
    const form = this.formTarget;
    form.requestSubmit()
  }, 200);

  handleCheckboxChange = () => {
    const form = this.formTarget;
    form.requestSubmit();
  };

  disconnect() {
    // Remover el event listener de todos los inputs
    this.inputTargets.forEach(input => {
      input.removeEventListener('input', this.handleInput);
    });
    // Para todos los selects
    this.selectTargets.forEach(select => {
      select.removeEventListener('change', this.handleInput);
    });
    this.checkboxTargets.forEach(checkbox => {
      checkbox.removeEventListener('change', this.handleCheckboxChange);
    });
  }

  reset() {
    // Restablecer todos los campos de entrada y selección a su valor predeterminado
    this.inputTargets.forEach(input => input.value = "");
    this.selectTargets.forEach(select => select.selectedIndex = 0);
    
    // Enviar el formulario para actualizar la lista de jugadores
    this.formTarget.submit();
  }

  submit(event) {
    event.preventDefault();
    this.formTarget.requestSubmit();
  }
}
