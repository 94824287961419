// fullscreen_controller.js

import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    if (!document.fullscreenEnabled) {
      this.element.remove();
    }
  }

  toggle() {
    if (!document.fullscreenElement) {
      this.element.requestFullscreen().catch(err => {
        console.error(`Error al intentar activar el modo pantalla completa: ${err.message} (${err.name})`);
      });
    } else {
      document.exitFullscreen();
    }
  }
}
