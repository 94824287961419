// app/javascript/controllers/drill_controller.js

import { Controller } from "stimulus"
import debounce from "lodash/debounce"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.search = debounce(this.search.bind(this), 300)
  }

  handleInput(event) {
    event.preventDefault()
    this.search()
  }

  search() {
    this.element.requestSubmit()
  }

  resetAndSubmit(event) {
    setTimeout(() => {
      this.element.requestSubmit();
    });
  }

}
