import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    // Definiendo los valores por defecto
    this.defaultZoom = 1.0;
    this.zoomStep = 0.025;
    
    this.defaultWidth = 85;
    this.defaultFontSize = 14;

    // Usando los valores de localStorage si existen, de lo contrario los valores por defecto.
    this.currentZoom = parseFloat(localStorage.getItem("zoomLevel")) || this.defaultZoom;
    this.currentWidth = parseInt(localStorage.getItem("buttonWidth")) || this.defaultWidth;
    this.currentFontSize = parseInt(localStorage.getItem("fontSize")) || this.defaultFontSize;

    this.applyStyles();
    this.updateDisplayTags();
  }

  updateDisplayTags() {
      document.getElementById("currentZoom").textContent = `Zoom: ${Math.ceil(this.currentZoom * 100)}%`;
      document.getElementById("currentWidth").textContent = `Ancho de botón: ${this.currentWidth}px`;
      document.getElementById("currentFontSize").textContent = `Tamaño de la fuente: ${this.currentFontSize}px`;
  }

  applyStyles() {
    $(".btn-measure").css("width", this.currentWidth + "px");
    $(".button-text").css("font-size", this.currentFontSize + "px");
  }

  updateZoomTag() {
    document.getElementById("currentZoom").textContent = `Zoom: ${Math.ceil(this.currentZoom * 100)}%`;
  }

  // Esta es tu nueva función que manejará la aplicación del zoom y el ajuste del ancho del cuerpo
  applyZoomAndAdjustBody() {
    document.body.style.transform = `scale(${this.currentZoom})`;
    document.body.style.transformOrigin = 'top left';
    document.body.style.width = `${(100 / this.currentZoom)}%`;
  }

  // Actualiza tus funciones zoomIn y zoomOut para utilizar la nueva función
  zoomIn() {
    this.currentZoom += this.zoomStep;
    this.applyZoomAndAdjustBody(); // Llama a la nueva función aquí
    localStorage.setItem("zoomLevel", this.currentZoom);
    this.updateZoomTag();
  }

  zoomOut() {
    this.currentZoom -= this.zoomStep;
    if (this.currentZoom < this.zoomStep) this.currentZoom = this.zoomStep;
    this.applyZoomAndAdjustBody(); // Llama a la nueva función aquí
    localStorage.setItem("zoomLevel", this.currentZoom);
    this.updateZoomTag();
  }

  increaseWidth() {
    this.currentWidth += 1;
    this.adjustButtons(this.currentWidth);
    localStorage.setItem("buttonWidth", this.currentWidth);
  }

  decreaseWidth() {
    if (this.currentWidth > 10) {
      this.currentWidth -= 1;
      this.adjustButtons(this.currentWidth);
      localStorage.setItem("buttonWidth", this.currentWidth);
    }
  }

  adjustButtons(newWidth) {
    document.querySelectorAll(".btn-measure").forEach((btn) => {
      btn.style.width = `${newWidth}px`;
    });
    document.getElementById("currentWidth").textContent = `Ancho de botón: ${newWidth}px`;
  }

  increaseFontSize() {
    this.currentFontSize += 1;
    this.adjustFont(this.currentFontSize);
    localStorage.setItem("fontSize", this.currentFontSize);
  }

  decreaseFontSize() {
    if (this.currentFontSize > 5) {
      this.currentFontSize -= 1;
      this.adjustFont(this.currentFontSize);
      localStorage.setItem("fontSize", this.currentFontSize);
    }
  }

  adjustFont(newSize) {
    document.querySelectorAll(".button-text").forEach((btnText) => {
      btnText.style.fontSize = `${newSize}px`;
    });
    document.getElementById("currentFontSize").textContent = `Tamaño de la fuente: ${newSize}px`;
  }
}
