// switch_controller.js
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['switch']

  update(event) {
    const url = this.switchTarget.getAttribute('data-url')
    const paramName = this.switchTarget.getAttribute('name')
    const csrfToken = getMetaValue('csrf-token')

    const formData = new URLSearchParams();
    formData.append(paramName, this.switchTarget.checked);

    fetch(url, {
      method: 'POST',
      body: formData,
      headers: {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  }
}

function getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  return element.getAttribute('content')
}
