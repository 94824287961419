import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["add_item", "template"]
  static values = { index: String }

  add_association(event) {
    event.preventDefault()

    var content = this.templateTarget.innerHTML.replace(new RegExp(this.indexValue, 'g'), new Date().valueOf())
    this.add_itemTarget.insertAdjacentHTML('beforebegin', content)
  }
  
  remove_association(event) {
    event.preventDefault()
    event.stopPropagation()
    let item = event.target.closest(".nested-fields")
  
    if (item) {
      let requiredInputs = item.querySelectorAll("input[required]")
      requiredInputs.forEach(input => input.removeAttribute('required'))
      item.querySelector("input[name*='_destroy']").value = 1
      item.style.display = 'none'
    } else {
      console.error("Error: unable to find .nested-fields element.");
    }
  }
}