// app/javascript/controllers/team_generator_controller.js
import { Controller } from "stimulus"
import bootstrap from "bootstrap"

export default class extends Controller {
  static targets = [ "teamPlayerMode", "teamPlayerSize", "sample", "info", "attend", "present", "all", "advancedMode", "advancedFormFields" ]

  connect() {
    this.calculateTeams();
  }

  calculateTeams() {
    var attend = parseInt(this.attendTarget.dataset.value);
    var present = parseInt(this.presentTarget.dataset.value);
    var all = parseInt(this.allTarget.dataset.value);
    var team_players_size = this.teamPlayerSizeTarget.value;
    var sample = this.sampleTarget.value;
    var team_player_mode = this.teamPlayerModeTarget.value;
    var info = this.infoTarget;

    var sample_size;

    switch (sample) {
      case 'confirmed_players':
        sample_size = attend;
        break;
      case 'present_players':
        sample_size = present;
        break;
      case 'all':
        sample_size = all;
        break;
      default:
        sample_size = attend;
    }

    var number_of_teams;

    switch (team_player_mode) {
      case 'by_players':
        number_of_teams = Math.floor(sample_size / team_players_size);
        break;
      case 'by_teams':
        number_of_teams = team_players_size;
        break;
    }

    // Update the info text
    info.innerHTML = "Cantidad de jugadores de la muestra: " + sample_size + " - Cantidad de equipos que se crearán: " + number_of_teams;
  }
  
  showAdvancedFields() {
    if (this.advancedModeTarget.checked) {
      this.advancedFormFieldsTarget.classList.remove("d-none");
    } else {
      this.advancedFormFieldsTarget.classList.add("d-none");
    }
  }

}
