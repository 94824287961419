import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox"]

  toggleCheckbox(event) {
    const checkbox = event.currentTarget.querySelector("input[type='checkbox']");
    checkbox.checked = !checkbox.checked;
  }

  toggleAll(event) {
    const checked = event.currentTarget.checked;
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = checked;
    });
  }
}
