import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "start", 
    "finish", 
    "departure", 
    "kind", 
    "homeAway", 
    "travel",
    "travelSwitch",
    "travelTypeDiv", 
    "stopListSection", 
    "eventTravelType", 
    "invitedPlayersFields", 
    "fullTeamEvent", 
    "invitedPlayers", 
    "matchFields", 
    "othersFields", 
    "homeSection", 
    "awaySection",
    "travelSection",
    "fieldIds",
    "location"
  ]

  connect() {
    this.startTarget.addEventListener('change', this.updateFinish.bind(this));
    this.homeAwayFields();
    this.showMatchFields();
    this.showInvitedPlayersFields();
  }

  updateFinish() {
    if (this.startTarget.value) {
      let startDate = moment(this.startTarget.value);
      startDate.add(90, 'minutes'); // Añade 90 minutos

      this.finishTarget.value = startDate.format('YYYY-MM-DDTHH:mm'); // Asegúrate de que el formato coincida con el de tus inputs
    }
  }

  changeFinishAndDepartureDates() {
    let minDate = this.startTarget.value;
    this.finishTarget.value = minDate;
    this.finishTarget.min = minDate;
    this.departureTarget.value = minDate;
  }

  showMatchFields() {
    switch (this.kindTarget.value) {
      case 'match':
          this.matchFieldsTarget.classList.remove("d-none");
          this.matchFieldsTarget.classList.add("d-flex");
          this.othersFieldsTarget.classList.remove("d-flex");
          this.othersFieldsTarget.classList.add("d-none");
        break;
      case 'others':
          this.othersFieldsTarget.classList.remove("d-none");
          this.othersFieldsTarget.classList.add("d-flex");
          this.matchFieldsTarget.classList.remove("d-flex");
          this.matchFieldsTarget.classList.add("d-none");
        break;
      case 'tour':
          this.othersFieldsTarget.classList.remove("d-none");
          this.othersFieldsTarget.classList.add("d-flex");
          this.matchFieldsTarget.classList.remove("d-flex");
          this.matchFieldsTarget.classList.add("d-none");
        break;
      default:
          this.othersFieldsTarget.classList.remove("d-flex");
          this.othersFieldsTarget.classList.add("d-none");
          this.matchFieldsTarget.classList.remove("d-flex");
          this.matchFieldsTarget.classList.add("d-none");
    }
  }

  homeAwayFields() {
    switch (this.homeAwayTarget.value) {
      case 'home':
          this.homeSectionTarget.classList.add("d-flex");
          this.homeSectionTarget.classList.remove("d-none");
          this.awaySectionTarget.classList.add("d-none");
          this.awaySectionTarget.classList.remove("d-flex");
          this.travelSectionTarget.classList.add("d-none");
          this.travelSectionTarget.classList.remove("d-flex");
          this.travelTypeDivTarget.classList.add("d-none");
          this.travelTypeDivTarget.classList.remove("d-flex");
          this.travelSwitchTarget.classList.add("d-none");
          this.travelSwitchTarget.classList.remove("d-flex");
          this.locationTarget.removeAttribute('required');
        break;
      case 'away':
          this.awaySectionTarget.classList.add("d-flex");
          this.awaySectionTarget.classList.remove("d-none");
          this.travelSectionTarget.classList.add("d-flex");
          this.travelSectionTarget.classList.remove("d-none");
          this.homeSectionTarget.classList.add("d-none");
          this.homeSectionTarget.classList.remove("d-flex");
          this.locationTarget.setAttribute('required', '');
          this.travelSwitchTarget.classList.add("d-flex");
          this.travelSwitchTarget.classList.remove("d-none");
          this.showTravelTypeFields();
        break;
      default:
          this.homeSectionTarget.classList.remove("d-flex");
          this.awaySectionTarget.classList.add("d-none");
          this.travelSwitchTarget.classList.add("d-none");
    }
  }

  addPlayers(event) {
    let players = JSON.parse(event.target.dataset.players);
    let previousValues = this.invitedPlayersTarget.tomselect.items;
    let updatedValues = [...previousValues, ...players];
    this.invitedPlayersTarget.tomselect.setValue(updatedValues);
  }

  showInvitedPlayersFields() {
    if (this.fullTeamEventTarget.checked) {
      this.invitedPlayersFieldsTarget.classList.remove("d-flex");
      this.invitedPlayersFieldsTarget.classList.add("d-none");
      if (this.invitedPlayersTarget.tomselect) {
        this.invitedPlayersTarget.tomselect.clear();
      }
    } else {
      this.invitedPlayersFieldsTarget.classList.remove("d-none");
      this.invitedPlayersFieldsTarget.classList.add("d-flex");
    }
  }

  showTravelTypeFields() {
    if (this.travelTarget.checked) {
      this.travelTypeDivTarget.classList.remove("d-none");
      this.travelTypeDivTarget.classList.add("d-flex");
      this.showStopList();
      this.eventTravelTypeTarget.setAttribute('required', true);
    }
    else {
      this.travelTypeDivTarget.classList.add("d-none");
      this.travelTypeDivTarget.classList.remove("d-flex");
      this.stopListSectionTarget.classList.add("d-none");
      this.stopListSectionTarget.classList.remove("d-flex");
      this.travelTypeDivTarget.removeAttribute('required');
    }
  }

  showStopList() {
    if (this.travelTarget.checked) {
      if (this.eventTravelTypeTarget.value == 'bus' || this.eventTravelTypeTarget.value == 'both') {
        this.stopListSectionTarget.classList.remove("d-none");
        this.stopListSectionTarget.classList.add("d-flex");
      } else {
        this.stopListSectionTarget.classList.add("d-none");
        this.stopListSectionTarget.classList.remove("d-flex");
      }
    }
  }
}
