import { Controller } from "stimulus";
import Timer from 'easytimer.js';
import beepSound from '../sounds/beep2secs.mp3';  // Asegúrate de ajustar la ruta del sonido

export default class extends Controller {
  static targets = ["display", "intervalName", "intervalDescription", "startButton", "pauseButton", "resetButton", "intervalIndex", "card", "fullscreenContainer", "nextIntervalInfo"];

  connect() {
    console.log("Timer Controller Connected");
    this.initializeTimer();
    this.loadSessionData();
    this.renderCurrentInterval();
    this.updateNextIntervalInfo();
  }

  initializeTimer() {
    this.timer = new Timer();
    this.timer.removeEventListener('secondsUpdated');
    this.timer.removeEventListener('targetAchieved');
    
    this.timer.addEventListener('secondsUpdated', () => {
      this.updateRemainingTime();
    });

    this.timer.addEventListener('targetAchieved', () => {
      this.handleIntervalCompletion();
    });
  }

  loadSessionData() {
    const sessionData = this.element.getAttribute("data-session");
    this.trainingSession = JSON.parse(sessionData);
    console.log("Parsed Session Data:", this.trainingSession);

    this.currentIntervalIndex = 0;
    this.currentInterval = this.trainingSession.intervals[this.currentIntervalIndex];
    this.remainingTime = this.currentInterval.duration_seconds;
  }

  async playBeepSound() {
    try {
      const audio = new Audio(beepSound);
      await audio.play();
      console.log("Beep sound played successfully");
    } catch (error) {
      console.error("Error playing beep sound:", error);
    }
  }

  renderCurrentInterval() {
    console.log("Rendering interval:", this.currentInterval);

    const intervalColor = this.currentInterval.color || "light";
    const textColor = this.currentInterval.text_color || "dark";
    this.cardTarget.className = `card shadow-lg mb-4 bg-${intervalColor} ${textColor}`;

    this.intervalNameTarget.textContent = this.currentInterval.name;
    this.intervalDescriptionTarget.textContent = this.currentInterval.description || "Sin descripción";
    this.intervalIndexTarget.textContent = `${this.currentIntervalIndex + 1} / ${this.trainingSession.intervals.length}`;

    this.updateDisplay();
    this.applyCurrentIntervalStyles();
    this.updateNextIntervalInfo();
  }

  updateNextIntervalInfo() {
    const nextIntervalIndex = this.currentIntervalIndex + 1;
    if (nextIntervalIndex < this.trainingSession.intervals.length) {
      const nextInterval = this.trainingSession.intervals[nextIntervalIndex];
      this.nextIntervalInfoTarget.innerHTML = `<strong>Siguiente intervalo:</strong> ${nextInterval.name} - ${Math.floor(nextInterval.duration_seconds / 60)} min`;
    } else {
      this.nextIntervalInfoTarget.innerHTML = "<span class='text-muted'>No hay más intervalos programados</span>";
    }
  }

  toggleFullscreen() {
    const fullscreenContainer = this.fullscreenContainerTarget;
  
    // Verificar si estamos en pantalla completa
    const isFullscreen = document.fullscreenElement || 
                         document.webkitFullscreenElement || 
                         document.msFullscreenElement;
  
    if (!isFullscreen) {
      // Intentar entrar en pantalla completa
      if (fullscreenContainer.requestFullscreen) {
        fullscreenContainer.requestFullscreen().then(() => {
          this.applyCurrentIntervalStyles(); // Aplicar el estilo de fondo cuando entramos a fullscreen
        }).catch(err => {
          console.error(`Error al intentar activar la pantalla completa: ${err.message}`);
        });
      } else if (fullscreenContainer.webkitRequestFullscreen) { // Safari
        fullscreenContainer.webkitRequestFullscreen();
        this.applyCurrentIntervalStyles();
      } else if (fullscreenContainer.msRequestFullscreen) { // IE/Edge
        fullscreenContainer.msRequestFullscreen();
        this.applyCurrentIntervalStyles();
      } else {
        console.warn("Pantalla completa no es compatible con este navegador.");
      }
    } else {
      // Intentar salir de pantalla completa
      if (document.exitFullscreen) {
        document.exitFullscreen().catch(err => {
          console.error(`Error al salir de la pantalla completa: ${err.message}`);
        });
      } else if (document.webkitExitFullscreen) { // Safari
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { // IE/Edge
        document.msExitFullscreen();
      } else {
        console.warn("No se pudo salir de la pantalla completa. No es compatible con este navegador.");
      }
    }
  }  

  // Asegurarse de aplicar el estilo correcto para el intervalo actual
  applyCurrentIntervalStyles() {
    const intervalColor = this.currentInterval.color || "light";
    const textColor = this.currentInterval.text_color || "dark";
    
    // Aplicar los colores al contenedor en pantalla completa también
    this.fullscreenContainerTarget.className = `card-body text-center bg-${intervalColor} text-${textColor}`;
  }

  startTimer() {
    if (this.timer.isRunning()) {
      console.log("Timer is already running, ignoring start request.");
      return;
    }

    console.log(`Starting timer for interval: ${this.currentIntervalIndex}, Duration: ${this.remainingTime} seconds`);
    this.playBeepSound();
    this.timer.start({ countdown: true, startValues: { seconds: this.remainingTime } });

    this.startButtonTarget.disabled = true;
    this.pauseButtonTarget.disabled = false;
  }

  refreshIntervals() {
    if (confirm("Se actualizará el timer con el orden actual de los intervalos. ¿Deseas continuar?")) {
      const token = document.querySelector('meta[name="csrf-token"]').content;

      // Enviar la solicitud al servidor para refrescar los intervalos
      Rails.ajax({
        url: this.data.get("refresh-url"), // URL que especificaremos en el HTML
        type: 'GET',
        headers: {
          "X-CSRF-Token": token
        },
        success: () => {
          console.log("Intervalos actualizados correctamente.");
        },
        error: (error) => {
          console.error("Error al actualizar los intervalos:", error);
        }
      });
    }
  }

  pauseTimer() {
    console.log("Pausing timer");
    this.timer.pause();
    this.startButtonTarget.disabled = false;
    this.pauseButtonTarget.disabled = true;
  }

  resetAll() {
    console.log("Resetting entire timer");
  
    // Mostrar una advertencia antes de resetear
    if (!confirm("¿Estás seguro de que quieres reiniciar todo el temporizador? Perderás el progreso actual.")) {
      return;
    }
  
    // Detenemos el temporizador
    this.timer.stop();
  
    // Reiniciar al primer intervalo
    this.currentIntervalIndex = 0;
    this.currentInterval = this.trainingSession.intervals[this.currentIntervalIndex];
    this.remainingTime = this.currentInterval.duration_seconds;
  
    // Renderizar el primer intervalo
    this.renderCurrentInterval();
  
    // Actualizar botones
    this.startButtonTarget.disabled = false;
    this.pauseButtonTarget.disabled = true;
  
    console.log("Timer reset to first interval.");
  }

  resetCurrentInterval() {
    console.log("Resetting current interval");

    // Mostrar una advertencia antes de resetear el intervalo
    if (!confirm("¿Estás seguro de que quieres reiniciar el intervalo actual?")) {
      return;
    }

    this.timer.stop();  // Detenemos el temporizador
    this.remainingTime = this.currentInterval.duration_seconds;  // Restablecemos el tiempo del intervalo
    this.renderCurrentInterval();  // Actualizamos la vista
    this.startTimer();  // Iniciamos el temporizador del intervalo reseteado
  }

  updateRemainingTime() {
    this.remainingTime = this.timer.getTotalTimeValues().seconds;
    this.updateDisplay();
  }

  handleIntervalCompletion() {
    console.log(`Interval ${this.currentIntervalIndex} completed`);

    if (this.currentIntervalIndex + 1 >= this.trainingSession.intervals.length) {
      console.log("All intervals completed, stopping timer.");
      this.stopTimer();
    } else {
      this.moveToNextInterval();
    }
  }

  moveToNextInterval() {
    this.timer.stop();
    this.currentIntervalIndex++;
    this.currentInterval = this.trainingSession.intervals[this.currentIntervalIndex];
    this.remainingTime = this.currentInterval.duration_seconds;

    console.log(`Moving to interval ${this.currentIntervalIndex}: ${this.currentInterval.name}`);
    this.renderCurrentInterval();
    this.startTimer();
  }

  stopTimer() {
    console.log("Stopping timer");
    this.timer.stop();
    this.startButtonTarget.disabled = false;
    this.pauseButtonTarget.disabled = true;
  }

  updateDisplay() {
    const minutes = Math.floor(this.remainingTime / 60);
    const seconds = this.remainingTime % 60;
    this.displayTarget.textContent = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    console.log("Updated display:", this.displayTarget.textContent);
  }

  previousInterval() {
    if (this.currentIntervalIndex === 0) {
      console.log("Already at the first interval, cannot go back further.");
      return;
    }

    console.log("Going to previous interval.");
    this.timer.stop();  // Detenemos el temporizador actual

    this.currentIntervalIndex--;  // Decrementamos el índice del intervalo
    this.currentInterval = this.trainingSession.intervals[this.currentIntervalIndex];
    this.remainingTime = this.currentInterval.duration_seconds;

    this.renderCurrentInterval();  // Actualizamos la vista
    this.startTimer();  // Iniciamos el temporizador para el intervalo anterior
  }

  skipInterval() {
    if (this.currentIntervalIndex + 1 >= this.trainingSession.intervals.length) {
      console.log("No more intervals left, cannot skip.");
      this.stopTimer();
      return;
    }

    console.log("Skipping current interval.");
    this.moveToNextInterval();  // Pasamos al siguiente intervalo
  }

  disconnect() {
    console.log("Timer Controller disconnected, stopping timer");
    this.timer.stop();
  }
}
