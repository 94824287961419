import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "start", 
    "finish", 
    "fieldIds"
  ]

  connect() {
    this.startTarget.addEventListener('change', this.updateFinish.bind(this));
  }

  updateFinish() {
    if (this.startTarget.value) {
      let startDate = moment(this.startTarget.value);
      startDate.add(90, 'minutes'); // Añade 90 minutos

      this.finishTarget.value = startDate.format('YYYY-MM-DDTHH:mm'); // Asegúrate de que el formato coincida con el de tus inputs
    }
  }

  changeFinishAndDepartureDates() {
    let minDate = this.startTarget.value;
    this.finishTarget.value = minDate;
    this.finishTarget.min = minDate;
  }
}
