import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["switch"]

  connect() {
    this.toggleAnimation(this.switchTarget.checked)
  }

  toggle() {
    this.toggleAnimation(this.switchTarget.checked)
  }

  toggleAnimation(animate) {
    fetch(`/update_animation_preference`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ animate: animate }),
      credentials: 'include',
    }).then(response => {
      if (response.ok) {
        console.log('Preferencia actualizada');
      } else {
        console.error('Error al actualizar la preferencia');
      }
    });
  }
}