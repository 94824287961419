import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: ".handle",  // Esta es la clase que usamos en nuestro "handle" visual
      animation: 150,
      onEnd: this.end.bind(this)
    });
  }

  end(event) {
    const items = this.element.querySelectorAll('.nested-fields');
    items.forEach((item, index) => {
      const newPosition = index + 1;
      const positionField = item.querySelector('[name*="[position]"]');
      if (positionField) {
        positionField.value = newPosition;
      }
  
      // Update visual position display if present
      const positionDisplay = item.querySelector('.position-display');
      if (positionDisplay) {
        positionDisplay.textContent = newPosition;
      }
    });
  }
}
