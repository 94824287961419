// app/javascript/controllers/text_truncation_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["text"];

  connect() {
    this.maxLength = 200;
    this.originalText = this.textTarget.innerHTML.trim();
    if (this.originalText.length > this.maxLength) {
      this.truncateText = `${this.originalText.substring(0, this.maxLength)}... (click para leer más)`;
      this.textTarget.innerHTML = this.truncateText;
      this.isTruncated = true;
    } else {
      this.textTarget.innerHTML = this.originalText;
      this.isTruncated = false;
    }
  }

  toggle() {
    if (this.isTruncated) {
      if (this.textTarget.innerHTML.includes("... (click para leer más)")) {
        this.textTarget.innerHTML = this.originalText;
      } else {
        this.textTarget.innerHTML = this.truncateText;
      }
    }
  }
}
