import { Controller } from "stimulus"
import $ from "jquery"
import "select2"

export default class extends Controller {
  static values = {
    closeOnSelect: Boolean,
    allowClear: Boolean,
    placeholder: String
  }

  connect() {
    $(this.element).select2({
      theme: 'bootstrap-5',
      placeholder: this.placeholderValue || null,
      closeOnSelect: this.hasCloseOnSelectValue ? this.closeOnSelectValue : true,
      allowClear: this.hasallowClearValue ? this.allowClearValue : true,
    });
  }

  disconnect() {
    $(this.element).select2('destroy');
  }
}