import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["role", "playerFields", "clubSelect", "teamSelect"]

  connect() {
    this.showParentFields();
    if(!this.teamSelectTarget.options.length) {
      this.teamSelectTarget.disabled = true;
    }

    $(this.clubSelectTarget).select2();
  }

  showParentFields() {
    let section = this.playerFieldsTarget;
    let roleSelect = this.roleTarget;

    if (roleSelect.value == "parent") {
      section.classList.remove("d-none");
      section.classList.add("d-flex");
      document.getElementById("player_name").required = true;
      document.getElementById("player_last_name").required = true;
      document.getElementById("player_birthdate").required = true;
    } else {
      section.classList.remove("d-flex");
      section.classList.add("d-none");
      document.getElementById("player_name").required = false;
      document.getElementById("player_last_name").required = false;
      document.getElementById("player_birthdate").required = false;
    }
  }

  populateTeams() {
    let clubSelect = this.clubSelectTarget;
    let teamSelect = this.teamSelectTarget;
    let club = clubSelect.value;

    if (club == '') {
      teamSelect.disabled = true;
    } else {
      teamSelect.disabled = false;
      fetch(`/club_teams?club=${club}`, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        }
      })
      .then(response => response.json())
      .then(data => {
        let teams = data["teams"];
        teamSelect.innerHTML = "";

        let defaultOption = document.createElement("option");
        defaultOption.text = "Seleccione un equipo";
        teamSelect.add(defaultOption);

        teams.forEach((team) => {
          let option = document.createElement("option");
          option.text = team["name"];
          option.value = team["id"];
          teamSelect.add(option);
        });
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    }
  }
}
