import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    new TomSelect(this.element, {
      plugins: ['remove_button', 'clear_button'],
    })
  }

  addPlayers(event) {
    const playerIds = JSON.parse(event.target.dataset.players);
    playerIds.forEach(playerId => this.tomSelect.addItem(playerId));
  }

}
