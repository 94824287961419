// app/javascript/controllers/evaluation_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["skillEvaluation", "skillNameDiv"];

  connect() {
    this.toggleSkillNameField();
  }

  toggleSkillNameField() {
    if (this.skillEvaluationTarget.checked) {
      this.skillNameDivTarget.classList.remove("d-none");
      this.skillNameDivTarget.classList.add("d-flex");
    } else {
      this.skillNameDivTarget.classList.remove("d-flex");
      this.skillNameDivTarget.classList.add("d-none");
    }
  }
}
