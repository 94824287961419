import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "parentInfo"]

  connect() {
    this.toggleFields();
  }

  toggleFields() {
    const isChecked = this.checkboxTarget.checked;
    this.parentInfoTargets.forEach((field) => {
      if (isChecked) {
        field.removeAttribute('disabled');
        field.setAttribute('required', true);
      } else {
        field.setAttribute('disabled', true);
        field.removeAttribute('required');
        field.value = '';
      }
    });
  }
}
