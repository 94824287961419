import { Controller } from "@hotwired/stimulus"
import html2canvas from "html2canvas"

export default class extends Controller {
  download(event) {
    event.preventDefault()
    const elementId = event.currentTarget.dataset.elementId
    const width = parseInt(event.currentTarget.dataset.width, 10) || 600
    const height = parseInt(event.currentTarget.dataset.height, 10) || 850
    const filename = event.currentTarget.dataset.filename || 'download'
    const element = document.getElementById(elementId)
    
    if (!element) {
      console.error('Element not found:', elementId)
      return
    }

    // Log the HTML of the element to the console
    console.log(element.outerHTML)

    html2canvas(element, {
      useCORS: true,
      allowTaint: false,
      logging: true,
      scale: 2, // Aumenta la escala para mayor resolución
      width: width,
      height: height
    }).then(canvas => {
      const link = document.createElement('a')
      link.href = canvas.toDataURL('image/png')
      link.download = `${filename}.png`
      link.click()
    }).catch(error => {
      console.error('Error converting HTML to canvas:', error)
    })
  }
}
