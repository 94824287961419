import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import debounce from "lodash/debounce"

export default class extends Controller {
  static targets = [ "clubs", "input" ]

  connect() {
  }

  search = debounce(() => {
    this.element.requestSubmit()
  }, 200)

  handleInput(event) {
    event.preventDefault()
    this.search()
  }
}