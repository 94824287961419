import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
    const handleSelector = this.data.get("handle")

    this.sortable = Sortable.create(this.element, {
      animation: 150,
      onEnd: this.end.bind(this),
      handle: handleSelector || undefined
    })

    // Busca el switch en el documento completo
    const dragToggle = document.getElementById('dragToggle');

    // Si el switch está presente, establece el comportamiento de arrastrar y soltar basado en su estado.
    // Si no está presente, habilita el arrastrar y soltar por defecto.
    if (dragToggle) {
        this.sortable.option("sort", dragToggle.checked);
    } else {
        this.sortable.option("sort", true);
    }

    dragToggle.addEventListener('change', this.toggleDrag.bind(this));

  }

  toggleDrag(event) {
    const isChecked = event.target.checked;
    this.sortable.option("sort", isChecked);
    this.data.set("dragEnabled", isChecked);
  }

  end(event) {

    if (dragToggle && !dragToggle.checked) {
      return;
    }

    let id = event.item.dataset.id
    let data = new FormData()
    data.append('position', event.newIndex + 1)
    data.append('dragToggleEnabled', dragToggle.checked);

    const token = document.querySelector('meta[name="csrf-token"]').content



    Rails.ajax({
      url: this.data.get("url").replace(":id", id),
      type: 'PATCH',
      data: data,
      processData: false,
      headers: {
        "X-CSRF-Token": token
      }
    })
  }
}
