import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    // Recoger manualmente los datos
    let options = [];
    this.element.querySelectorAll('option').forEach(option => {
        if (option.value) {
            options.push({
                value: option.value,
                text: option.textContent,
                src: option.dataset.src
            });
        }
    });

    // Inicializar TomSelect con los datos recogidos
    new TomSelect(this.element, {
        options: options,
        items: [this.element.value],
        minChars: 3,
        render: {
          option: function(data, escape) {
            return '<div class="option d-flex align-items-center">' +
                   '<img src="' + escape(data.src) + '" style="width: 60px; height: 60px; margin-right: 10px;" alt="Club logo">' + 
                   '<span class="label">' + escape(data.text) + '</span>' +
                   '</div>';
          },
          item: function(data, escape) {
            return '<div class="option d-flex align-items-center">' +
                   '<img src="' + escape(data.src) + '" style="width: 60px; height: 60px; margin-right: 10px;" alt="Club logo">' + 
                   '<span class="label">' + escape(data.text) + '</span>' +
                   '</div>';
          }
        }
    });
  }

}
