import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "score", "scoreRelated" ]

  connect() {
    this.toggleScoreRelatedFields();
  }

  toggleScoreRelatedFields() {
    if (this.scoreTarget.checked) {
      this.showScoreRelatedFields();
      this.setInputRequired(true);
    } else {
      this.hideScoreRelatedFields();
      this.setInputRequired(false);
    }
  }

  showScoreRelatedFields() {
    this.scoreRelatedTargets.forEach(el => el.classList.remove('d-none'));
  }

  hideScoreRelatedFields() {
    this.scoreRelatedTargets.forEach(el => el.classList.add('d-none'));
  }

  setInputRequired(isRequired) {
    const scoreValueInputs = this.element.querySelectorAll('.score-related input[type="number"]');
    const teamSelects = this.element.querySelectorAll('.score-related select');
  
    scoreValueInputs.forEach(input => input.required = isRequired);
    teamSelects.forEach(select => select.required = isRequired);
  }

  restart() {
    this.toggleScoreFields();
  }
}
