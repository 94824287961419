import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["kind", "fromDate", "toDate", "injuriesFields", "fromLabel", "toLabel", "card", "severity"];

  connect() {
    this.showFields();
  }

  showFields() {
    this.fromDateTarget.style.display = "none";
    this.toDateTarget.style.display = "none";
    this.cardTarget.style.display = "none";
    this.severityTarget.style.display = "none";
    this.injuriesFieldsTarget.classList.remove("d-flex");
    this.injuriesFieldsTarget.classList.add("d-none");

    switch (this.kindTarget.value) {
      case 'Incidences::Injury':
        this.fromDateTarget.style.display = "block";
        this.injuriesFieldsTarget.classList.remove("d-none");
        this.injuriesFieldsTarget.classList.add("d-flex");
        this.severityTarget.classList.remove("d-none");
        this.severityTarget.classList.add("d-flex");
        break;
      case 'Incidences::Travel':
        this.fromDateTarget.style.display = "block";
        this.toDateTarget.style.display = "block";
        this.severityTarget.classList.add("d-none");
        this.severityTarget.classList.remove("d-flex");
        break;
      case 'Incidences::Other':
        this.fromDateTarget.style.display = "block";
        this.severityTarget.classList.add("d-none");
        this.severityTarget.classList.remove("d-flex");
        break;
      case 'Incidences::Discipline':
        this.severityTarget.classList.remove("d-none");
        this.severityTarget.classList.add("d-flex");
        this.fromDateTarget.style.display = "block";
        this.toDateTarget.style.display = "block";
        if (this.kindTarget.value === 'Incidences::Discipline') {
          this.cardTarget.style.display = "block";
        }
        this.fromLabelTarget.textContent = "Fecha";
        break;

      case 'Incidences::Health':
        this.fromLabelTarget.textContent = "Fecha";
        this.fromDateTarget.style.display = "block";
        this.severityTarget.classList.remove("d-none");
        this.severityTarget.classList.add("d-flex");
        break;
      case 'Incidences::Behaviour':
        this.fromDateTarget.style.display = "block";
        this.severityTarget.classList.remove("d-none");
        this.severityTarget.classList.add("d-flex");
        break;
    }
  }
}
