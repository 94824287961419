import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["advanced", "toggleAdvancedButtons", "togglePhotos", "avatar", "toggleProcessed", "processed"]
  static values = {
    showAdvancedButtons: Boolean,
    showPhotos: Boolean,
    hideProcessed: Boolean,
    userId: Number
  }

  connect() {
    this.toggleAdvancedButtonsTarget.checked = this.showAdvancedButtonsValue;
    this.togglePhotosTarget.checked = this.showPhotosValue;
    this.toggleProcessedTarget.checked = this.hideProcessedValue;
    this.updateVisibility();
    this.updateAvatarVisibility(this.showPhotosValue);
    this.updateProcessedVisibility();
    document.addEventListener('attendanceUpdated', () => {
      this.updateProcessedVisibility();
    });
  }

  toggleButtons() {
    this.advancedTargets.forEach(element => element.classList.toggle("d-none"));
    this.showAdvancedButtonsValue = this.toggleAdvancedButtonsTarget.checked;
    this.updatePreferencesOnServer();
  }

  togglePhotos() {
    const showPhotos = this.togglePhotosTarget.checked;
    this.showPhotosValue = this.togglePhotosTarget.checked;
    this.updatePreferencesOnServer();
    this.updateAvatarVisibility(showPhotos);
  }

  updateVisibility() {
    this.advancedTargets.forEach(element => {
      element.classList.toggle("d-none", !this.showAdvancedButtonsValue);
    });
  }

  updateAvatarVisibility(visible) {
    this.avatarTargets.forEach(element => {
      if (visible) {
        element.classList.remove("d-none");
        element.classList.add("d-inline");
      } else {
        element.classList.remove("d-inline");
        element.classList.add("d-none");
      }
    });
  }

  toggleProcessed() {
    const isProcessedShown = this.toggleProcessedTarget.checked;
    this.hideProcessedValue = isProcessedShown;
    this.updatePreferencesOnServer();
    this.updateProcessedVisibility();
  }

  updateProcessedVisibility() {
    const shouldHide = this.hideProcessedValue;
    this.processedTargets.forEach(element => {
      if (shouldHide) {
        element.classList.add("fadeOut");
        setTimeout(() => {
          element.classList.add("d-none");
          element.classList.remove("fadeOut");
        }, 500); 
      } else {
        element.classList.remove("d-none");
      }
    });
  }  
  

  updatePreferencesOnServer() {
    const preferences = {
      show_advanced_buttons: this.showAdvancedButtonsValue,
      show_photos: this.showPhotosValue,
      hide_processed: this.hideProcessedValue
    };

    fetch(`/users/${this.userIdValue}/update_attendance_preference`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(preferences),
      credentials: 'include',
    }).then(response => {
      if (!response.ok) {
        console.error('Error al actualizar la preferencia en el servidor');
      }
    });
  }

  disconnect() {
    document.removeEventListener('attendanceUpdated', () => {
      this.updateProcessedVisibility();
    });
  }

}
