// app/javascript/controllers/request_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  static values = { url: String, method: String, params: Object }

  connect() {
    this.element.addEventListener('change', this.sendRequest.bind(this))
  }

  sendRequest() {
    fetch(this.urlValue, {
      method: this.methodValue,
      headers: {
        "Content-Type": "application/json",
        "Accept": "text/vnd.turbo-stream.html, text/html, application/xhtml+xml",
        "X-CSRF-Token": this.getMetaValue("csrf-token")  // Include CSRF Token in headers
      },
      body: JSON.stringify(this.paramsValue)
    })
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    return element.getAttribute("content")
  }
}
