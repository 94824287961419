document.addEventListener('DOMContentLoaded', (event) => {
  async function registerServiceWorker() {
    if (!navigator.serviceWorker) return;
    try {
      await navigator.serviceWorker.register('/service-worker.js');
    } catch (error) {
      console.log("Service worker registration failed:", error);
    }
  }

  function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  async function startServiceWorker() {
    const vapidPublicKey = document.head.querySelector('meta[name="vapid_public_key"]').getAttribute("content");
    await registerServiceWorker();
    if (await requestNotificationPermission()) {
      const subscription = await getPushSubscription(vapidPublicKey);
      if (subscription) {
        await sendSubscriptionToServer(subscription);
      }
    }
  }

  async function getPushSubscription(vapidPublicKey) {
    const registration = await navigator.serviceWorker.getRegistration();
    if (!registration) return null;

    let subscription = await registration.pushManager.getSubscription();
    if (!subscription) {
      subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(vapidPublicKey)
      });
    }
    return subscription;
  }

  async function sendSubscriptionToServer(subscription) {
    const userAgentString = navigator.userAgent;
    const browser = Bowser.getParser(window.navigator.userAgent);
    const deviceInfo = {
      browser: {
        name: browser.getBrowserName(),
        version: browser.getBrowserVersion(),
      },
      os: {
        name: browser.getOSName(),
        version: browser.getOSVersion(),
      },
      platform: {
        type: browser.getPlatformType(),
        vendor: browser.getPlatform().vendor || 'Generic',
        model: browser.getPlatform().model || 'Desktop'
      }
    };

    return fetch("/notifications/subscribe", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.head.querySelector('meta[name="csrf-token"]').getAttribute("content")
      },
      body: JSON.stringify({
        worker: subscription.toJSON(),
        device_info: deviceInfo
      })
    });
  }

  async function requestNotificationPermission() {
    const permissionResult = await Notification.requestPermission();
    if (Notification.permission === "granted") {
      allowAlert.style.display = "none";
      return true;
    }
    return false;
  }

  registerServiceWorker();

  const allowButton = document.querySelector("#allow_notifications");
  const allowAlert = document.querySelector("#allow_notifications_alert");

  if (typeof Notification !== "undefined") {
    if (Notification.permission === "denied") {
      allowAlert.style.display = "none";
    } else if (Notification.permission === "granted") {
      startServiceWorker();
      allowAlert.style.display = "none";
    } else {
      allowAlert.style.display = "block";
    }

    allowButton.addEventListener("click", async () => {
      if (await requestNotificationPermission()) {
        startServiceWorker();
      }
    });
  }
});
