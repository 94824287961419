// app/javascript/controllers/full_calendar_controller.js
import { Controller } from "@hotwired/stimulus";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es"; // Importa el localizador español

export default class extends Controller {
  static targets = ["calendar"];
  static values = { url: String }

  connect() {
    this.initializeCalendar();
  }

  initializeCalendar() {
    const calendarEl = this.calendarTarget;
    const eventsUrl = this.urlValue; // Usa Stimulus values API

    const calendar = new Calendar(calendarEl, {
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, bootstrap5Plugin],
      initialView: "timeGridWeek",
      locale: esLocale,
      themeSystem: 'bootstrap5',
      headerToolbar: {
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
      },
      slotMinTime: "06:00:00",
      slotMaxTime: "22:00:00",
      // eventContent: (arg) => this.createEventContent(arg), // Personaliza el contenido del evento
      events: function(fetchInfo, successCallback, failureCallback) {
        // La URL ya no depende de inputs específicos para 'from' y 'to'
        const eventsFetchUrl = `${eventsUrl}?from=${fetchInfo.startStr}&to=${fetchInfo.endStr}`;

        fetch(eventsFetchUrl, {
          headers: { "Accept": "application/json" },
        })
          .then(response => response.json())
          .then(events => successCallback(events))
          .catch(error => failureCallback(error));
      },
      datesSet: (dateInfo) => {
        // Actualiza el enlace del botón de exportación a Excel
        const exportExcelButton = document.getElementById("export-excel-button");
        if (exportExcelButton) {
          const from = dateInfo.startStr;
          const to = dateInfo.endStr;
          const newHref = `${exportExcelButton.getAttribute("href").split("?")[0]}?from=${from}&to=${to}&format=xlsx`;
          exportExcelButton.setAttribute("href", newHref);
        }
      },
      eventClick: function(info) {
        info.jsEvent.preventDefault(); 
        const eventDetailsUrl = `/events/${info.event.id}/details`;
      
        fetch(eventDetailsUrl)
          .then(response => response.text())
          .then(html => {
            const modalElement = document.getElementById('eventDetailsModal');
            const modalBody = modalElement.querySelector('.modal-body');
            modalBody.innerHTML = html;
                  var bootstrapModal = new bootstrap.Modal(modalElement);
            bootstrapModal.show();
          })
          .catch(error => console.error('Error loading event details:', error));
      },
    });

    calendar.render();
  }
}

// createEventContent(arg) {
//   const { event } = arg;
//   const { confirmedPlayersCount, notDecidedPlayersCount, absentPlayersCount, unconfirmedPlayersCount } = event.extendedProps;

//   const eventContent = document.createElement('div');
//   eventContent.innerHTML = `<b>${event.title}</b><br>
//   (Confirmados: ${confirmedPlayersCount} | No Decididos: ${notDecidedPlayersCount} | Ausentes: ${absentPlayersCount} | Sin Confirmar: ${unconfirmedPlayersCount})
//   <div class="btn-group mr-2 mt-0 text-center" role="group">
//     <a class="btn btn-success btn-sm m-1 shadow border border-dark">
//       <img class="img-fluid d-inline" width="25" height="25" src="/assets/thumbs_up.png"> ${confirmedPlayersCount}
//     </a>
//     <a class="btn btn-warning btn-sm m-1 shadow border border-dark">
//       <img class="img-fluid d-inline" width="25" height="25" src="/assets/question.png"> ${notDecidedPlayersCount}
//     </a>
//     <a class="btn btn-danger btn-sm m-1 shadow border border-dark">
//       <img class="img-fluid d-inline" width="25" height="25" src="/assets/thumbs_down.png"> ${absentPlayersCount}
//     </a>
//     <a class="btn btn-light btn-sm m-1 shadow border border-dark"
//       <img class="img-fluid d-inline" width="25" height="25" src="/assets/clock2.png"> ${unconfirmedPlayersCount}
//     </a>
//   </div>`;

//   return { domNodes: [eventContent] };
// }