import { Controller } from "stimulus";
import { DataSet, Timeline } from "vis-timeline/standalone";
import consumer from "channels/consumer";

export default class extends Controller {
  static targets = ["container"];

  connect() {
    this.initTimeline();
    this.subscribeToEventsChannel();
  }

  initTimeline() {
    const eventsData = JSON.parse(this.data.get("events"));
    const items = new DataSet(eventsData);

    const zoomMin = parseInt(this.data.get("zoomMin"), 10);
    const zoomMax = parseInt(this.data.get("zoomMax"), 10);
    // const startTime = new Date(this.data.get("start"));
    
    const options = {
      zoomMin: zoomMin,
      zoomMax: zoomMax,
      editable: {
        add: true,
        updateTime: true,    // arrastrar items horizontalmente
        remove: true        // eliminar un item clicando en el botón de borrar en la parte superior derecha
      },
      onMove: (item, callback) => {
        this.handleUpdate(item, callback);
      },
      onRemove: (item, callback) => {
        this.handleRemove(item, callback);
      },
      onAdd: (item, callback) => {
        this.handleAdd(item, callback);
      },
    };
    
    this.timeline = new Timeline(this.containerTarget, items, options);
  }

  disconnect() {
    if (this.timeline) {
      this.timeline.destroy();
    }
    this.unsubscribeFromEventsChannel();
  }

  updateTimeline(event) {
    const event_start = new Date(event.start);
    this.timeline.itemsData.update(event); 
    this.centerViewOnEvent(event_start);
  }

  centerViewOnEvent(time) {
    this.timeline.moveTo(time);
  }

  subscribeToEventsChannel() {
    const instanceId = this.data.get("instanceId"); // Asegúrate de obtener el ID correcto, en este caso "instanceId" porque has utilizado "data-timeline-instance-id".

    this.subscription = consumer.subscriptions.create({ channel: "MatchStatsChannel", instance_id: instanceId }, {
      connected: () => {
        console.log("Conectado a MatchStatsChannel");
      },

      disconnected: () => {
        console.log("Desconectado de MatchStatsChannel");
      },

      received: (data) => {
        if (data.action && data.action === 'delete') {
          this.deleteFromTimeline(data.id);
        } else {
          this.updateTimeline(data); 
        }
      }
    });
  }

  deleteFromTimeline(itemId) {
    this.timeline.itemsData.remove(itemId); 
  }  

  unsubscribeFromEventsChannel() {
    if (this.subscription) {
      consumer.subscriptions.remove(this.subscription);
    }
  }

  handleUpdate(item, callback) {
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content'); // Esta línea obtiene el token

    fetch(`/match_stats_event_logs/${item.id}/move`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': token   // Añade esta línea
        },
        body: JSON.stringify(item)
    })
    .then(response => {
        if (response.ok) {
            return response.json();
        } else {
            throw new Error('Failed to update item');
        }
    })
    .then(data => {
      // hay que arreglar esto
      data.item.start = new Date(data.item.start);

      this.timeline.itemsData.update(data.item);
      callback(item);
    })
    .catch(error => {
        console.error('Update error:', error);
        callback(null);
    });
  }

  handleRemove(item, callback) {
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    fetch(`/match_stats_events/${item.event_id}/decrement?log_id=${item.id}`, { 
        method: 'PATCH', 
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': token   
        },
        body: JSON.stringify({
          sub_kind: item.sub_kind, // Suponiendo que el item tiene sub_kind
          log_id: item.id
        })
    })
    .then(response => {
        if (response.ok) {
          this.deleteFromTimeline(item.id);
          return response.json();
        } else {
          throw new Error('Failed to remove item');
        }
    })
    .then(data => {
        callback(item);
    })
    .catch(error => {
        console.error('Remove error:', error);
        callback(null);
    });
  }

}
