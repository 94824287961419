import { Controller } from "stimulus";
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Registro del plugin
Chart.register(ChartDataLabels);

export default class extends Controller {
  static values = {
    type: String,
    chartData: Object,
    title: String,
    legendDisplay: Boolean,
    hidePointLabels: Boolean,
    scaleBegin: Number,
    scaleEnd: Number
  }

  connect() {
    this.initializeChart();
  }

  initializeChart() {
    const canvas = this.element.querySelector('canvas');
    if (canvas) {
      const ctx = canvas.getContext('2d');
      const config = this.getChartConfig();
      new Chart(ctx, config);
    } else {
      console.error("No se encontró un elemento canvas");
    }
  }

  getChartConfig() {
    const options = {
      maintainAspectRatio: false,
      layout: {
        padding: {
          bottom: 20 // Ajusta este valor según sea necesario
        }
      },
      plugins: {
        title: {
          display: true,
          text: this.titleValue,
          font: {
            size: 18
          },
          padding: {
            top: 10,
            bottom: 30
          }
        },
        datalabels: this.hidePointLabelsValue ? false : {
          color: '#000',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          borderColor: 'black',
          borderWidth: 1,
          borderRadius: 4,
          formatter: (value, ctx) => {
            let sum = 0;
            let dataArr = ctx.chart.data.datasets[0].data;
            dataArr.map(data => {
              sum += data;
            });
            let percentage = ((value * 100) / sum).toFixed(1).replace('.', ',');
            return `${percentage}% (${value})`;
          },
          anchor: 'middle',
          align: 'end'
        },
        legend: {
          display: this.legendDisplayValue,
          position: 'right',
          padding: {
            top: 10,
            bottom: 30
          }
        }
      }
    };

    // Ajustes específicos para gráficos de tipo radar
    if (this.typeValue === 'radar') {
      options.scales = {
        r: {
          beginAtZero: true,
          min: this.scaleBeginValue || 0,
          max: this.scaleEndValue || 10,
          ticks: {
            stepSize: 2
          }
        }
      };
    }

    return {
      type: this.typeValue,
      data: this.chartDataValue,
      options: options
    };
  }
}
