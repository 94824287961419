import { Controller } from "@hotwired/stimulus"
import Inputmask from "inputmask"

// Connects to data-controller="inputmask"
export default class extends Controller {
  connect() {
    this.initialize()
  }

  initialize() {
    // Initialize input masks
    Inputmask().mask(this.element.querySelectorAll("input"))
  }
}
