import { Controller } from 'stimulus'
import { get, post } from '@rails/request.js'

export default class extends Controller {
  static targets = ['select']

  handleChange(event) {
    const attribute = this.selectTarget.getAttribute('data-attribute')
    const disableAfter = this.selectTarget.getAttribute('data-disable-after')
    const origin = this.selectTarget.getAttribute('data-origin')
    const url = this.selectTarget.getAttribute('data-url')
    const method = this.selectTarget.getAttribute('data-method')
    const paramsString = this.selectTarget.getAttribute('data-params')
    const params = new URLSearchParams(paramsString)

    // Agrega el valor actual del select a los parámetros
    params.append('value', this.selectTarget.value)

    // Deshabilita el botón radio
    if (disableAfter == true) {
      this.selectTarget.disabled = true;
      }

    if (method == 'get') {
      get(url + "?" + params.toString(), {
        responseKind: "turbo-stream"
      })
    } else if (method == 'post') {
      post(url, {
        body: params,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded', 
          'X-CSRF-Token': getMetaValue('csrf-token'), 
          'Accept': 'text/vnd.turbo-stream.html'
        }
      })
    }
  }
}

function getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`);
  return element.getAttribute('content');
}
