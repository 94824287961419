import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = ["interval", "dragToggle"]

  connect() {
    // Inicializar sortable pero deshabilitado por defecto
    this.initializeSortable(false);

    // Escuchar el cambio en el switch
    this.dragToggleTarget.addEventListener('change', this.toggleSortable.bind(this));
  }

  initializeSortable(enabled) {
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      ghostClass: 'blue-background-class',
      onEnd: this.end.bind(this), // Llama al método `end` cuando termina el reordenamiento
      disabled: !enabled, // Deshabilitado inicialmente
    });
  }

  toggleSortable(event) {
    const isEnabled = event.target.checked; // Ver si el toggle está activado o no
    console.log(`Reordenar intervalos ${isEnabled ? 'habilitado' : 'deshabilitado'}`);

    if (isEnabled) {
      // Habilitar el reordenamiento
      this.sortable.option("disabled", false);
    } else {
      // Deshabilitar el reordenamiento
      this.sortable.option("disabled", true);
    }
  }

  end(event) {
    // Recolecta los IDs y las nuevas posiciones de los intervalos
    let intervals = []
    this.element.querySelectorAll('.list-group-item').forEach((el, index) => {
      intervals.push({
        id: el.dataset.id,
        position: index + 1
      })
    })

    const token = document.querySelector('meta[name="csrf-token"]').content

    // Enviar las nuevas posiciones al backend
    Rails.ajax({
      url: this.data.get("url"), // Asegúrate de pasar la URL correctamente en los datos
      type: 'PATCH',
      data: new URLSearchParams({ intervals: JSON.stringify(intervals) }).toString(), // Envia los datos de los intervalos
      headers: {
        "X-CSRF-Token": token
      },
      success: () => {
        console.log("Positions updated successfully")
      },
      error: (error) => {
        console.error("Error updating positions:", error)
      }
    })
  }
}
