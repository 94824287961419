// app/javascript/controllers/dropzone_controller.js
import { Controller } from "stimulus";
import Dropzone from "dropzone";
import { get } from "@rails/request.js";

export default class extends Controller {
  connect() {
    const id = "#dropzone_instance";
    const dropzone = document.querySelector(id);
    const uploadUrl = this.data.get("url");
    const attachmentListUrl = this.data.get("attachmentsListUrl");
    const attachable = this.data.get("attachable");
    const attachableId = this.data.get("attachableId");
    
  
    // set the preview element template
    var previewNode = dropzone.querySelector(".dropzone-item");
    previewNode.id = "";
    var previewTemplate = previewNode.parentNode.innerHTML;
    previewNode.parentNode.removeChild(previewNode);
    const csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  
    var myDropzone = new Dropzone(id, {
      url: uploadUrl,
      headers: { "X-CSRF-Token": csrfToken },
      parallelUploads: 10,
      previewTemplate: previewTemplate,
      maxFilesize: 3,
      dictDefaultMessage: "Arrastra los archivos aquí para subirlos",
      autoQueue: false,
      previewsContainer: id + " .dropzone-items", 
      clickable: id + " .dropzone-select"
    });
  
    myDropzone.on("addedfile", function (file) {
      file.previewElement.querySelector(id + " .dropzone-start").onclick = function () { myDropzone.enqueueFile(file); };
      const dropzoneItems = dropzone.querySelectorAll('.dropzone-item');
      dropzoneItems.forEach(dropzoneItem => {
        dropzoneItem.style.display = '';
      });
      dropzone.querySelector('.dropzone-upload').style.display = "inline-block";
      dropzone.querySelector('.dropzone-remove-all').style.display = "inline-block";
    });
  
    myDropzone.on("totaluploadprogress", function (progress) {
      const progressBars = dropzone.querySelectorAll('.progress-bar');
      progressBars.forEach(progressBar => {
        progressBar.style.width = progress + "%";
      });
    });
  
    myDropzone.on("sending", function (file) {
      const progressBars = dropzone.querySelectorAll('.progress-bar');
      progressBars.forEach(progressBar => {
        progressBar.style.opacity = "1";
      });
      file.previewElement.querySelector(id + " .dropzone-start").setAttribute("disabled", "disabled");
    });
  
    myDropzone.on("complete", function (progress) {
      const progressBars = dropzone.querySelectorAll('.dz-complete');
  
      setTimeout(function () {
        progressBars.forEach(progressBar => {
          progressBar.querySelector('.progress-bar').style.opacity = "0";
          progressBar.querySelector('.progress').style.opacity = "0";
          progressBar.querySelector('.dropzone-start').style.opacity = "0";
        });
      }, 300);

      get(attachmentListUrl, {
        responseKind: "turbo-stream"
      })
    });
  
    dropzone.querySelector(".dropzone-upload").addEventListener('click', function () {
      myDropzone.enqueueFiles(myDropzone.getFilesWithStatus(Dropzone.ADDED));
    });
  
    dropzone.querySelector(".dropzone-remove-all").addEventListener('click', function () {
      dropzone.querySelector('.dropzone-upload').style.display = "none";
      dropzone.querySelector('.dropzone-remove-all').style.display = "none";
      myDropzone.removeAllFiles(true);
    });
  
    myDropzone.on("queuecomplete", function (progress) {
      const uploadIcons = dropzone.querySelectorAll('.dropzone-upload');
      uploadIcons.forEach(uploadIcon => {
        uploadIcon.style.display = "none";
      });
    });
  
    myDropzone.on("removedfile", function (file) {
      if (myDropzone.files.length < 1) {
        dropzone.querySelector('.dropzone-upload').style.display = "none";
        dropzone.querySelector('.dropzone-remove-all').style.display = "none";
      }
    });
  }

  disconnect() {
    if (this.dropzone) {
      this.dropzone.destroy();
    }
  }
}