import { Controller } from "stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["teams", "events", "primaryEventId"]

  loadTeams(event) {
    const clubId = event.target.value
    const eventId = this.primaryEventIdTarget.value
    const url = `/clubs/${clubId}/teams_for_club?event_id=${eventId}`

    get(url, {
      responseKind: "turbo-stream"
    })
  }

  loadEvents(event) {
    const teamId = event.target.value
    const eventId = this.primaryEventIdTarget.value
    const url = `/teams/${teamId}/events_for_team?event_id=${eventId}`

    get(url, {
      responseKind: "turbo-stream"
    })
  }
}
