import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.applyStyles();
  }

  applyStyles() {
    const width = localStorage.getItem("buttonWidth");
    const fontSize = localStorage.getItem("buttonFontSize");

    if (width) {
      this.element.style.width = width;
    }

    if (fontSize) {
      this.element.style.fontSize = fontSize;
    }
  }

}