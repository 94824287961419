// app/javascript/controllers/players_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["listView", "tilesView", "toggleButton", "detailsFrame", "filterrificResults"]

  connect() {

    this.toggleViewFromStorage(); // Establecer la vista basada en localStorage al cargar
  }

  toggleView() {
    if (this.toggleButtonTarget.dataset.currentView === "list") {
      this.listViewTarget.style.display = "none";
      this.tilesViewTarget.style.display = "block";
      this.toggleButtonTarget.dataset.currentView = "tiles";
      localStorage.setItem('playersView', 'tiles'); // Guardar preferencia
    } else {
      this.listViewTarget.style.display = "block";
      this.tilesViewTarget.style.display = "none";
      this.toggleButtonTarget.dataset.currentView = "list";
      localStorage.setItem('playersView', 'list'); // Guardar preferencia
    }
  }

  toggleViewFromStorage() {
    const savedView = localStorage.getItem('playersView');
    if (savedView) {
      this.toggleButtonTarget.dataset.currentView = savedView === 'list' ? 'tiles' : 'list'; // Forzar cambio de vista
      this.toggleView(); // Cambiar a la vista guardada
    }
  }
  
  loadPlayerDetails(event) {
    let playerId = event.currentTarget.dataset.playerId;
    // Cargar detalles del jugador con Turbo. Asume que tienes una ruta definida para esto.
    Turbo.visit(`/players/${playerId}/details`);
  }

  showDetails(event) {
    const playerId = event.currentTarget.dataset.playerId;
    const frame = this.detailsFrameTarget;
    this.filterrificResultsTarget.style.display = "none";

    frame.src = `/players/${playerId}/details`; 
    frame.style.display = "block"

    event.preventDefault();
  }
  
  hideDetails() {
    const frame = this.detailsFrameTarget;
    frame.style.display = "none";
    frame.src = "";

    this.filterrificResultsTarget.style.display = "block";
    event.preventDefault();
  }
}