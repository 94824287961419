import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["preview", "source", "subKind"];

  connect() {
    this.updateButton();
  }

  updateButton() {
    this.updateColor();
    this.updateText();
  }

  preview() {
    this.updateButton();
  }

  updateColor() {
    const colorName = this.sourceTarget.value;
    this.setButtonClass(colorName);
  }

  updateText() {
    this.previewTarget.innerText = this.subKindTarget.value || 'Ejemplo';
  }

  setButtonClass(colorName) {
    const btnClasses = [
      'btn-primary', 'btn-primary-subtle',
      'btn-secondary', 'btn-secondary-subtle',
      'btn-success', 'btn-success-subtle',
      'btn-danger', 'btn-danger-subtle',
      'btn-warning', 'btn-warning-subtle',
      'btn-info', 'btn-info-subtle',
      'btn-light', 'btn-light-subtle',
      'btn-dark', 'btn-dark-subtle',

      'bg-primary', 'bg-primary-subtle',
      'bg-secondary', 'bg-secondary-subtle',
      'bg-success', 'bg-success-subtle',
      'bg-danger', 'bg-danger-subtle',
      'bg-warning', 'bg-warning-subtle',
      'bg-info', 'bg-info-subtle',
      'bg-light', 'bg-light-subtle',
      'bg-dark', 'bg-dark-subtle'
    ]
    this.previewTarget.classList.remove(...btnClasses);
    this.previewTarget.classList.add(`btn-${colorName}`);
    this.previewTarget.classList.add(`bg-${colorName}`);
  }
}
