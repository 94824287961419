import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener("turbo:submit-end", () => {
      const event = new CustomEvent("close-modal", { bubbles: true });
      this.element.dispatchEvent(event);
    });
  }
}
