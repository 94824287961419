// app/javascript/controllers/rating_controller.js

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["stars"];

  connect() {
    console.log("Rating controller connected:", this.element);
    this.rateableId = this.element.dataset.ratingRateableId;
    this.rateableType = this.element.dataset.ratingRateableType;
  }

  submitRating(event) {
    const ratingValue = event.target.value;
    console.log(`Submitting rating ${ratingValue} for ${this.rateableType} #${this.rateableId}`);

    fetch(`/ratings`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content")
      },
      body: JSON.stringify({
        rating: {
          rateable_id: this.rateableId,
          rateable_type: this.rateableType,
          rating_value: ratingValue
        }
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log("Rating submitted successfully:", data);
        // Reemplaza el badge con el nuevo HTML recibido del servidor
        this.updateAverageScoreBadge(data.average_rating_badge);
      })
      .catch(error => {
        console.error("Error submitting rating:", error);
      });
  }

  updateAverageScoreBadge(averageRatingBadgeHtml) {
    const badgeElement = document.getElementById(`average_score_badge_${this.rateableId}`);
    if (badgeElement) {
      badgeElement.outerHTML = averageRatingBadgeHtml; // Reemplaza el HTML completo del badge
    }
  }
}
