import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import debounce from "lodash/debounce"

export default class extends Controller {
  static targets = [ "user", "input" ]
  minChars = 3

  connect() {
    console.log("Conectado") 
  }

  search = debounce(() => {
    if (this.inputTarget.value.length >= this.minChars) {
      this.element.requestSubmit()
    }
  }, 200)

  handleInput(event) {
    event.preventDefault()
    this.search()
  }
}