// controllers/animate_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    animation: String,
    afterAnimationAction: String
  }

  connect() {
    this.animate();
  }

  animate() {
    const animationClass = `animate__${this.animationValue}`;
    this.element.classList.add('animate__animated', animationClass);

    // Escuchar el evento de finalización de la animación
    this.element.addEventListener('animationend', this.afterAnimation);
  }

  afterAnimation = () => {
    switch (this.afterAnimationActionValue) {
      case "remove":
        this.element.parentElement.remove();
        break;
      // Agrega otros casos según sea necesario
      // case "otherAction":
      //   // Realiza otra acción
      //   break;
      default:
        // Acción por defecto o no hacer nada
        break;
    }
  }
}
