// app/javascript/controllers/toastr_controller.js
import { Controller } from "stimulus"
import toastr from 'toastr'

export default class extends Controller {
  connect() {
    this.setOptions();
    this.displayFlashMessages();
  }

  setOptions() {
    toastr.options = {
      "closeButton": false,
      "debug": false,
      "newestOnTop": true,
      "progressBar": true,
      "positionClass": "toast-top-right",
      "preventDuplicates": false,
      "onclick": null,
      "showDuration": "300",
      "hideDuration": "1000",
      "timeOut": "5000",
      "extendedTimeOut": "1000",
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "fadeIn",
      "hideMethod": "fadeOut"
    }
  }

  displayFlashMessages() {
    const flashMessages = JSON.parse(this.element.dataset.messages);
  
    if (flashMessages.length > 0) {
      flashMessages.forEach((flash, index, array) => {
        var key = flash[0];
        var value = flash[1];
        var type = key.replace('alert', 'error').replace('notice', 'success');

        toastr[type](value);
  
        // Añadir una callback para que se ejecute cuando el toastr se oculte
        toastr.options.onHidden = () => {
          this.removeElement();
        };

      });
    }
  }
  
  removeElement() {
    const unique_id = this.element.dataset.flashId;
  
    // Elimina el div de flash
    this.element.remove();
  
    // Elimina el turbo-frame por el ID único
    const turboFrame = document.getElementById(unique_id);
    if (turboFrame) {
      turboFrame.remove();
    }
  }
  
  disconnect() {
    // Elimina el elemento del DOM
    this.element.remove();
  }
  
}