import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['button'];

  copy() {
    const url = this.buttonTarget.dataset.url;
    fetch(url)
      .then(response => response.text())
      .then(data => {
        this.copyToClipboard(data);
      })
      .catch(error => {
        console.error('Error al obtener los datos:', error);
        alert('Error al obtener los datos.');
      });
  }

  copyToClipboard(text) {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text)
        .then(() => {
          alert('Copiado al portapapeles');
        })
        .catch((error) => {
          console.error('Error al copiar al portapapeles:', error);
          fallbackCopyToClipboard(text);
        });
    } else {
      fallbackCopyToClipboard(text);
    }
  }
  
  fallbackCopyToClipboard(text) {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.style.position = 'fixed';
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
  
    try {
      document.execCommand('copy');
      alert('Copiado al portapapeles');
    } catch (error) {
      console.error('Error al copiar al portapapeles:', error);
      alert('Error al copiar al portapapeles');
    }
  
    document.body.removeChild(textarea);
  }

}
