import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = ["player", "list"]

  connect() {
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      ghostClass: 'blue-background-class',
      onEnd: this.end.bind(this),
      group: {
        name: 'squads'
      }
    })
  }

  end(event) {
    let id = event.item.dataset.id
    let squad_name = event.to.getAttribute("name")
    let data = new FormData()
    data.append('position', event.newIndex + 1)
    data.append('squad_name', squad_name)

    const token = document.querySelector('meta[name="csrf-token"]').content

    Rails.ajax({
      url: this.data.get("url").replace(":id", id),
      type: 'PATCH',
      data: data,
      processData: false,
      headers: {
        "X-CSRF-Token": token
      }
    })
  }

  moveToSquad(event) {
    const playerId = event.target.dataset.playerId;
    const squadName = event.target.dataset.squadName;
    const data = new FormData();
    data.append("squad_name", squadName);
    const token = document.querySelector('meta[name="csrf-token"]').content
  
    Rails.ajax({
      url: `/players/${playerId}/assign_squad`,
      type: 'PATCH',
      data: data,
      processData: false,
      headers: {
        "X-CSRF-Token": token
      },
      success: (response) => {
        // Remove the player's li element from the original squad list
        const playerElement = this.playerTargets.find(element => element.dataset.id == playerId);
        const oldList = playerElement.closest('.list-group');
        oldList.removeChild(playerElement);
  
        // Add the player's li element to the new squad list
        const newList = document.querySelector(`[data-squads-id="${squadName}"]`);
        newList.appendChild(playerElement);
  
        // Re-initialize SortableJS for the new list, if needed
        if (!newList.sortable) {
          newList.sortable = Sortable.create(newList, {
            group: {
              name: 'squads'
            },
            animation: 150,
            ghostClass: 'blue-background-class',
            onEnd: this.end.bind(this),
          });
        }
  
      },
      error: (error) => {
        console.error(error);
      }
    });
  }
}
