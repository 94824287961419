import { Controller } from 'stimulus'
import { get, post } from '@rails/request.js'
import debounce from 'lodash/debounce'

export default class extends Controller {
  static targets = ['input']

  handleChange = debounce((event) => {
    const attribute = this.inputTarget.getAttribute('data-attribute')
    const origin = this.inputTarget.getAttribute('data-origin')
    const url = this.inputTarget.getAttribute('data-url')
    const method = this.inputTarget.getAttribute('data-method')
    const paramsString = this.inputTarget.getAttribute('data-params')
    const params = new URLSearchParams(paramsString)

    // Add the current value of the input to the parameters
    params.append('value', this.inputTarget.value)

    if (method == 'get') {
      get(url + "?" + params.toString(), {
        responseKind: "turbo-stream"
      })
    } else if (method == 'post') {
      post(url, {
        body: params,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded', 
          'X-CSRF-Token': getMetaValue('csrf-token'), 
          'Accept': 'text/vnd.turbo-stream.html'
        }
      })
    }
  }, 1000) // delay de 1 segundo
}

function getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`);
  return element.getAttribute('content');
}
