import { Controller } from "stimulus";
import { Modal } from "bootstrap";
import addSound from '../sounds/add.mp3';
import removeSound from '../sounds/remove.mp3';

// Función para obtener el contenido de una etiqueta meta por su nombre
function getMetaContent(name) {
  const header = document.querySelector(`meta[name="${name}"]`);
  return header ? header.getAttribute('content') : null;
}

export default class extends Controller {
  static targets = ["badge", "checkbox"];

  connect() {
    this.applyStyles();
  }

  increment() {
    const matchStatsEventId = this.data.get("event-id");
    const subKind = this.data.get("sub-kind");
    const team = this.data.get("team");
    const score = this.data.get("score");
    const badgeId = this.data.get("badge-id");
    const badgeElement = document.getElementById(badgeId);
    const buttonElement = this.element;

    buttonElement.disabled = true;

    if (!matchStatsEventId) {
      console.error('matchStatsEventId is not set');
      return;
    }

    fetch(`/match_stats_events/${matchStatsEventId}/increment`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getMetaContent('csrf-token')
      },
      body: JSON.stringify({
        sub_kind: subKind,
        score: score,
        team: team
      }),
      credentials: 'same-origin',
    })
    .then(response => {
      if (!response.ok) {
        return response.json().then(errData => {
          throw new Error(errData.message || 'Network response was not ok');
        });
      }
      return response.json();
    })
    .then(data => {
      badgeElement.innerText = data.new_count;
      this.incrementSound()
      this.openModalWithForm(data.match_stats_event_log_id);
    })
    .catch(error => {
      alert(error.message);
    });
  }

  decrement() {
    const matchStatsEventId = this.data.get("event-id");
    const subKind = this.data.get("sub-kind");
    const badgeId = this.data.get("badge-id");
    const badgeElement = document.getElementById(badgeId);
    const buttonElement = this.element;

    buttonElement.disabled = true;

    if (!matchStatsEventId) {
      console.error('matchStatsEventId is not set');
      return;
    }

    fetch(`/match_stats_events/${matchStatsEventId}/decrement`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getMetaContent('csrf-token')
      },
      body: JSON.stringify({
        sub_kind: subKind
      }),
      credentials: 'same-origin',
    })
    .then(response => {
      if (!response.ok) {
        return response.json().then(errData => {
          throw new Error(errData.message || 'Network response was not ok');
        });
      }
      return response.json();
    })
    .then(data => {
      badgeElement.innerText = data.new_count;
      this.decrementSound()
    })
    .catch(error => {
      alert(error.message);
    });
  }

  applyStyles() {
    const defaultWidth = 85;
    const defaultFontSize = 14;

    const buttonWidth = localStorage.getItem("buttonWidth") || defaultWidth;
    const fontSize = localStorage.getItem("fontSize") || defaultFontSize;

    $(".btn-measure").css("width", buttonWidth + "px");
    $(".button-text").css("font-size", fontSize + "px");
  }

  openModalWithForm(logId) {
    const eventId = this.data.get("event-id");
    const checkbox = document.getElementById(`checkbox_${eventId}`);
    
    if (checkbox && checkbox.checked) {
      fetch(`/match_stats_event_logs/${logId}/edit`)
      .then(response => response.text())
      .then(html => {
        const modalBodyElement = document.querySelector('#event_button_modal .modal-body');
        const modalElement = document.querySelector('#event_button_modal');
        
        modalBodyElement.innerHTML = html;
        
        const formInModal = modalBodyElement.querySelector('form');
        if (formInModal) {
          formInModal.addEventListener('submit', this.handleFormSubmit.bind(this));
        }

        const modalInstance = Modal.getInstance(modalElement);
        if (!modalInstance) {
          new Modal(modalElement).show();
        } else {
          modalInstance.show();
        }
      })
      .catch(error => {
        console.error('Error loading edit form:', error);
      });
    }
  }

  handleFormSubmit(event) {    
    const modalElement = document.querySelector('#event_button_modal');
    const modalInstance = Modal.getInstance(modalElement);
    if (modalInstance) {
      modalInstance.hide();
    }
  }

  incrementSound() {
    const audio = new Audio(addSound);
    audio.play();
  }

  decrementSound() {
    const audio = new Audio(removeSound);
    audio.play();
  }
}
