import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["source", "card"];

  connect() {
    this.updateBackground(); // Actualizar el fondo al conectar el controlador por si hay un valor preseleccionado
  }

  // Método que se ejecuta cuando el usuario cambia el valor del color
  preview() {
    this.updateBackground();
  }

  // Actualizar la clase de fondo basada en el color seleccionado
  updateBackground() {
    const color = this.sourceTarget.value;
    
    // Elimina cualquier clase anterior de color de fondo de Bootstrap
    this.cardTarget.classList.remove(...this.getBootstrapColorClasses());

    // Añade la nueva clase de color seleccionada
    if (color) {
      this.cardTarget.classList.add(`bg-${color}`);
    }
  }

  // Obtener las posibles clases de color de Bootstrap
  getBootstrapColorClasses() {
    return [
      'bg-primary', 'bg-primary-subtle',
      'bg-secondary', 'bg-secondary-subtle',
      'bg-success', 'bg-success-subtle',
      'bg-danger', 'bg-danger-subtle',
      'bg-warning', 'bg-warning-subtle',
      'bg-info', 'bg-info-subtle',
      'bg-light', 'bg-light-subtle',
      'bg-dark', 'bg-dark-subtle',
      'bg-orange', 'bg-orange-subtle',
      'bg-purple', 'bg-purple-subtle',
      'bg-pink', 'bg-pink-subtle',
      'bg-teal', 'bg-teal-subtle'
    ];
  }
}
