import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["badge"];

  increment() {
    const kind = this.data.get("kind");
    const subKind = this.data.get("subKind");
    const scope = this.data.get("scope");

    // Llama a la API o realiza alguna acción para incrementar el contador
    // ...

    // Incrementa el contador del badge para este botón en particular
    this.badgeTarget.innerText = parseInt(this.badgeTarget.innerText) + 1;
  }
}